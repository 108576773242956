

























































































































import SaveButton from '@/components/base/SaveButton.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { ModalMixin } from '@/core/modal-mixin';
import { CenterCallSettingsMapper } from '@/organizations/locations/mappers/center-call-settings-mapper';
import {
    CenterCallSettings,
    CenterCallSettingsDto

} from '@/organizations/locations/models/center-call-settings';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import cloneDeep from 'lodash/cloneDeep';
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import {
    getCrmPhoneNumberPluralizedLabel,
    getClientCallSettingsModalDescription
} from '@/organizations/locations/center-utils';

const mapper = new CenterCallSettingsMapper();
const centersStore = getModule(CentersStore);

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class ClientCallSettingsModal extends Mixins(BasicValidationMixin, LocaleMixin, ModalMixin) {
    /**
     * The center to modify call settings for.
     */
    @Ref('form') readonly form!: VForm;

    private dto: CenterCallSettingsDto | null = null;
    private isValid = false;
    private loadingKey = '';

    /**
     * Whether the form can be saved or not.
     */
    private get canSave() {
        return this.isValid;
    }

    /**
     * Has single center.
     */
    private get hasSingleCenter() {
        return centersStore.storedAccessibleCenters.length === 1;
    }

    /**
     * The title of the modal.
     */
    private get clientCallSettingsModalTitle() {
        return `Get ${getCrmPhoneNumberPluralizedLabel(this.hasSingleCenter)}`;
    }

    /**
     * The description of the modal
     */
    private get clientCallSettingsModalDescription() {
        return getClientCallSettingsModalDescription(this.hasSingleCenter);
    }

    async created() {
        await centersStore.initAccessibleCenters();
    }

    /**
     * Handles operations when the modal is shown or hidden.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.form) {
            this.form.reset();
        }
        if (this.modelValue) {
            // Create the dto and other stuff
            this.dto = mapper.toCreateDto({} as CenterCallSettings);
        }
    }

    /**
     * Request a phone number and save the settings.
     */
    private save() {
        if (!this.dto) {
            return;
        }
        this.$emit(EventTypes.UPDATED, cloneDeep(this.dto));
        this.close();
    }
}
