import { AbstractRepository } from '@/repositories/abstract-repository';
import { ClientInfo, ClientInfoDto } from '@/organizations/corporate/models/client-info';
import { Logo, LogoDto } from '@/logo/models/logo';

export class ClientRepository extends AbstractRepository<ClientInfo> {
    readonly endpoint = 'settings/client';

    public async getClientInfo(): Promise<ClientInfo> {
        const clientInfoResult = await this.client.get(this.endpoint);
        return clientInfoResult.data as ClientInfo;
    }

    public async updateClientInfo(clientInfo: ClientInfoDto, startA2PRegistration = false): Promise<ClientInfo> {
        const params = startA2PRegistration ? { start_a2p_registration: true } : {};
        const clientInfoResult = await this.client.put(this.endpoint, clientInfo, { params });
        return clientInfoResult.data as ClientInfo;
    }

    public async deleteLogo(): Promise<void> {
        await this.client.delete<void>(`${this.endpoint}/logo`);
    }

    public async getLogo(): Promise<Logo | null> {
        try {
            const response = await this.silentClient.get<Logo>(`${this.endpoint}/logo`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    public async uploadLogo(logoDto: LogoDto): Promise<Logo> {
        const response = await this.client.put<Logo>(`${this.endpoint}/logo`, logoDto);
        return response.data;
    }

    public async deleteWhiteLabelLogo(): Promise<void> {
        await this.client.delete<void>(`${this.endpoint}/logo/whitelabel`);
    }

    public async getWhiteLabelLogo(): Promise<Logo | null> {
        try {
            const response = await this.silentClient.get<Logo>(`${this.endpoint}/logo/whitelabel`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    public async uploadWhiteLabelLogo(logoDto: LogoDto): Promise<Logo> {
        const response = await this.client.put<Logo>(`${this.endpoint}/logo/whitelabel`, logoDto);
        return response.data;
    }

    public async getWhiteLabelLogoContent(): Promise<string | null> {
        try {
            const response = await this.silentClient.get<string>(`${this.endpoint}/logo/whitelabel/file`, { responseType: 'blob' });
            return response.data;
        } catch (e) {
            return null;
        }
    }
}
