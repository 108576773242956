import { TwilioCountriesAbbr } from '@/core/country-constants';
import { CrmApiElement } from '@/models/base';
import { SimpleAddress, SimpleAddressDto } from '@/core/models/simple-address';
import { SimplePhone, SimplePhoneDto } from '@/core/models/simple-phone';
import { StaffHateoas } from '@/organizations/locations/models/center';
import { SocialMediaLink, SocialMediaLinkDto } from '@/core/models/social-media-link';
import {
    DlcRegistrationStageConstants,
    DlcRegistrationStatusConstants
} from '@/organizations/corporate/constants/dlc-registration-constants';

export interface ClientInfo extends CrmApiElement {
    name: string;
    address: SimpleAddress;
    phone: SimplePhone;
    url: string;
    admin_staff: StaffHateoas | null;
    marketing_staff: StaffHateoas | null;
    billing_account_name: string;
    billing_address: SimpleAddress;
    billing_contact_first_name: string;
    billing_contact_last_name: string;
    billing_contact_phone: SimplePhone;
    billing_contact_email: string;
    billing_contact_job_title: string;
    billing_contact_job_position: string;
    business_type: string;
    iso_country: string | TwilioCountriesAbbr;
    business_regions_of_operation: string;
    sales_contact_first_name: string;
    sales_contact_last_name: string;
    sales_contact_phone: SimplePhone;
    sales_contact_email: string;
    comments: string;
    is_transitioned: boolean;
    center_variable_tags_count?: number;
    social_media: SocialMediaLink;
    business_registration_identifier: string;
    legal_business_name: string;
    dlc_registration_stage: DlcRegistrationStageConstants | null;
    dlc_registration_status: DlcRegistrationStatusConstants | null;
}

export class ClientInfoDto {
    name = '';
    address = new SimpleAddressDto();
    phone = new SimplePhoneDto();
    url = '';
    admin_staff: number | null = null;
    marketing_staff: number | null = null;
    billing_account_name = '';
    billing_address = new SimpleAddressDto();
    billing_contact_first_name = '';
    billing_contact_last_name = '';
    billing_contact_phone = new SimplePhoneDto();
    billing_contact_email = '';
    billing_contact_job_title = '';
    billing_contact_job_position = '';
    business_type = '';
    iso_country = '';
    business_regions_of_operation = '';
    sales_contact_first_name = '';
    sales_contact_last_name = '';
    sales_contact_phone = new SimplePhoneDto();
    sales_contact_email = '';
    comments = '';
    center_variable_tags_count: number | undefined = undefined;
    social_media = new SocialMediaLinkDto();
    business_registration_identifier = '';
    legal_business_name = '';
}
