

































































































































































































































































































































































































































































































































































































































































import { ClientCountries, TwilioCountriesAbbr } from '@/core/country-constants';
import MiscUtils from '@/core/utils/misc-utils';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { ClientUtils } from '@/organizations/corporate/utils/client-utils';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { ClientInfo, ClientInfoDto } from '@/organizations/corporate/models/client-info';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import { ClientInfoMapper } from '@/organizations/corporate/mappers/client-info-mapper';
import isEqual from 'lodash/isEqual';
import { SocialMediaRootURLConstants } from '@/core/models/social-media-link';
import UploadLogo from '@/automation/landing-pages/components/UploadLogo.vue';
import { Logo } from '@/logo/models/logo';
import { LogoMapper } from '@/logo/mappers/logo-mapper';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { getCrmPhoneNumberPluralizedLabel } from '@/organizations/locations/center-utils';
import cloneDeep from 'lodash/cloneDeep';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';
import ClientCallSettingsModal from '@/organizations/corporate/components/ClientCallSettingsModal.vue';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { CenterCallSettingsDto } from '@/organizations/locations/models/center-call-settings';

const loadingState = getModule(LoadingStore);
const clientInfoMapper = new ClientInfoMapper();
const centersStore = getModule(CentersStore);
const logoMapper = new LogoMapper();
const clientUtils = new ClientUtils();
const clientRepository = new ClientRepository();
const centersRepository = new CentersRepository();

@Component({
    components: { ClientCallSettingsModal, UploadLogo, SaveButton }
})
export default class ManageClientInfo extends Mixins(LocaleMixin, BasicValidationMixin, VmodelShowMixin) {
    @Prop({ required: true }) readonly clientInfo!: ClientInfo | null;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'clientInfoEditModal';
    private validForm = true;
    private clientInfoDto: ClientInfoDto = new ClientInfoDto();
    private accountInfoPanelIndex: number | undefined = -1;
    private socialMediaLinksPanelIndex: number | undefined = -1;
    private logo: Logo | null = null;
    private panelIndex: number | undefined = -1;
    private maxLength = 200;
    private logoExists = false;
    private logoChange = false;
    private commentRows = 3;
    private originalName = '';
    private descriptionRules = [(v = '') => v.length <= this.maxLength || `Max ${this.maxLength} characters`];
    private facebookRootConstant = SocialMediaRootURLConstants.FACEBOOK;
    private youtubeRootConstant = SocialMediaRootURLConstants.YOUTUBE;
    private linkedinRootConstant = SocialMediaRootURLConstants.LINKEDIN;
    private instagramRootConstant = SocialMediaRootURLConstants.INSTAGRAM;
    private twitterRootConstant = SocialMediaRootURLConstants.TWITTER;
    private pinterestRootConstant = SocialMediaRootURLConstants.PINTEREST;
    private updatedEvent = EventTypes.UPDATED;
    private showUploadDialog = false;
    private descriptionCountDown = (v = '') => {
        return this.maxLength - v.length;
    };

    private jobPositions = ['Director', 'GM', 'VP', 'CEO', 'CFO', 'General Counsel', 'Other'];

    private businessTypes = ['Co-operative', 'Corporation', 'Limited Liability', 'Non-profit Corporation', 'Partnership'];

    private countryOptions = MiscUtils.getObjectValues(TwilioCountriesAbbr);

    private regionOptions = ['AFRICA', 'ASIA', 'EUROPE', 'LATIN_AMERICA', 'USA_AND_CANADA'];

    private showClientCallSettingsDialog = false;

    // Computed
    get hasChanges(): boolean {
        return (this.clientInfo !== null &&
            (
                !isEqual(this.clientInfoDto, clientInfoMapper.toUpdateDto(this.clientInfo))
            )
        );
    }

    /**
     * Tooltip message for the business name field.
     */
    private get businessNameTooltip() {
        return clientUtils.getBusinessNameTooltip(this.clientInfoDto.iso_country);
    }

    /**
     * Label for the business registration field.
     */
    private get businessRegistrationLabel() {
        return clientUtils.getBusinessRegistrationLabel(this.clientInfoDto.iso_country);
    }

    /**
     * Tooltip for the business registration field.
     */
    private get businessRegistrationTooltip() {
        return clientUtils.getBusinessRegistrationTooltip(this.clientInfoDto.iso_country);
    }

    /**
     * Validation rules for the business registration field.
     */
    private get businessRegistrationValidationRules() {
        return clientUtils.getBusinessRegistrationValidationRules(this.clientInfoDto.iso_country);
    }

    /**
     * Has single center.
     */
    private get hasSingleCenter() {
        return centersStore.storedAccessibleCenters.length === 1;
    }

    /**
     * Phone locale for the phone number input.
     */
    private get applyCrmPhoneNumberButtonLabel() {
        return `Apply for ${getCrmPhoneNumberPluralizedLabel(this.hasSingleCenter)}`;
    }

    /**
     * Whether to show the apply CRM phone number button.
     */
    private get showApplyCrmPhoneNumberButton() {
        if (!this.clientInfo) {
            return false;
        }
        return clientUtils.showApplyCrmNumberButton(this.clientInfo.dlc_registration_status);
    }

    private get showDlcStatusMessage() {
        if (!this.clientInfo) {
            return false;
        }
        return clientUtils.showDlcStatusMessage(this.clientInfo.dlc_registration_status, this.clientInfo.dlc_registration_stage);
    }

    private get dlcStatusMessage() {
        if (!this.clientInfo) {
            return '';
        }
        return clientUtils.getStatusLabel(this.clientInfo.dlc_registration_status, this.clientInfo.dlc_registration_stage, this.hasSingleCenter);
    }

    /**
     * Client country
     */
    private get country() {
        if (!this.clientInfo) {
            return '';
        }
        return this.clientInfo.billing_address.country;
    }

    // Watches (not clocks)
    @Watch('modelValue')
    private async doOpen() {
        if (this.modelValue) {
            if (this.clientInfo !== null) {

                this.clientInfoDto = clientInfoMapper.toUpdateDto(this.clientInfo);
                // Let the DOM update, before trying to validate, or it won't validate correctly.
                this.originalName = this.clientInfo.name;
                this.logo = await clientRepository.getLogo();
                this.logoExists = !!this.logo;
                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });
            }

        }
    }

    // Life cycle
    async created() {
        // Handle key presses (ESC)
        document.addEventListener('keyup', this.handleKeyup);
        await centersStore.initAccessibleCenters();
    }

    private async deleteLogo() {
        const result = await this.$swal({
            text: 'Are you sure you want to delete the logo for this organization?',
            showConfirmButton: true,
            showCancelButton: true
        });
        if (result.isConfirmed) {
            this.logo = null;
        }
        this.logoChange = this.logoExists;
    }

    // Methods
    private handleKeyup(e: any): void {
        if (e.keyCode === 27 && this.modelValue) {
            // Escape out of editing mode.
            this.modelValue = false;
            this.accountInfoPanelIndex = -1;
            this.socialMediaLinksPanelIndex = -1;
        }
    }

    private handleClose() {
        this.form.reset();
        this.logoChange = false;
        this.logoExists = false;
        this.logo = null;
        this.panelIndex = -1;
        this.accountInfoPanelIndex = -1;
        this.socialMediaLinksPanelIndex = -1;
        this.modelValue = false;
    }

    private logoUploaded(logo: Logo) {
        this.logo = logo;
        this.showUploadDialog = false;
        this.logoChange = true;
    }

    private async save(centerCallSettingsDto: CenterCallSettingsDto | null = null): Promise<void> {
        loadingState.loadingIncrement(this.loadingKey);
        try {
            const clientDtoToSave = cloneDeep(this.clientInfoDto);
            clientUtils.cleanSocialMediaLinks(clientDtoToSave);
            await clientRepository.updateClientInfo(clientDtoToSave, this.country === ClientCountries.UNITED_STATES);
            if (this.country !== ClientCountries.UNITED_STATES) {
                // Begin provision number for all centers right away if it's not a US client
                await centersRepository.bulkProvisionCenterNumbers();
            }

            if (centerCallSettingsDto) {
                await centersRepository.bulkUpdateCenterCallSettings(centerCallSettingsDto);
            }

            // Handle logo if needed
            if (this.logoExists && (this.logo === null)) {
                await clientRepository.deleteLogo();
            } else if (this.logo) {
                await clientRepository.uploadLogo(logoMapper.toCreateDto(this.logo));
            }

        } catch (e) {
            // Do nothing!
        }
         if (this.originalName !== this.clientInfoDto.name) {
            await this.$router.go(0);
        }

        loadingState.loadingDecrement(this.loadingKey);

        // Emit that we updated something.
        this.$emit(EventTypes.UPDATED);

        // Close the modal
        this.handleClose();
    }

    private updateByCountry() {
        if (this.clientInfoDto?.business_registration_identifier?.length) {
            this.$nextTick(() => {
                this.form.validate();
            });
        }
    }

}
