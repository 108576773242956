import { TwilioCountriesAbbr } from '@/core/country-constants';
import {
    DlcRegistrationStageConstants,
    DlcRegistrationStatusConstants
} from '@/organizations/corporate/constants/dlc-registration-constants';
import { getCrmPhoneNumberPluralizedLabel } from '@/organizations/locations/center-utils';
import { ClientInfoDto } from '@/organizations/corporate/models/client-info';

/**
 * Handles utility functionality for client.
 */
export class ClientUtils {
    /**
     * Get the business name tooltip message.
     *
     * @param country
     */
    public getBusinessNameTooltip(country: TwilioCountriesAbbr | string): string {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return 'This must exactly match the business name on the business formation documents submitted to your Secretary of State in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.CANADA:
                return 'This must exactly match the business name on the business formation documents submitted to Corporations Canada in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.AUSTRALIA:
                return 'This must exactly match the business name on the business formation documents submitted to the Australian Securities & Investments Commission in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return 'This must exactly match the business name on the business formation documents submitted to Companies House in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.NEW_ZEALAND:
                return 'This must exactly match the business name on the business formation documents submitted to the Companies Office in order to enable the Texting and/or Call Recording features.';
            case TwilioCountriesAbbr.INDIA:
            default:
                return 'This must match exactly the business name on your formation documents to enable Texting and/or Call Recording features.';
        }
    }

    /**
     * Get the business registration label.
     *
     * @param country
     */
    public getBusinessRegistrationLabel(country: TwilioCountriesAbbr | string): string {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return 'Employer Identification Number (EIN)';
            case TwilioCountriesAbbr.CANADA:
                return 'Business Number (BN)';
            case TwilioCountriesAbbr.AUSTRALIA:
                return 'Australian Business Number (ABN)';
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return 'Unique Taxpayer Reference (UTR)';
            case TwilioCountriesAbbr.NEW_ZEALAND:
                return 'New Zealand Business Number (NZBN)';
            case TwilioCountriesAbbr.INDIA:
                return 'Permanent Account Number (PAN)';
            default:
                return 'Business Registration Number';
        }
    }

    /**
     * Get the business registration validation rules.
     *
     * @param country
     */
    public getBusinessRegistrationValidationRules(country: TwilioCountriesAbbr | string) {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return [(value: string) => /^[0-9]{2}-[0-9]{7}$/.test(value) || 'Your EIN should be formatted XX-XXXXXXX'];
            case TwilioCountriesAbbr.CANADA:
                return [(value: string) => /^[0-9]{9}$/.test(value) || 'Your BN should be 9 digits long'];
            case TwilioCountriesAbbr.AUSTRALIA:
                return [
                    (value: string) =>
                        /^[0-9]{2} [0-9]{3} [0-9]{3} [0-9]{3}$/.test(value) || 'Your ABN should be formatted XX XXX XXX XXX'
                ];
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return [
                    (value: string) => {
                        // Normalize input: remove spaces, special characters, and ensure lowercase for "k"
                        const normalized = value.replace(/\s+/g, '').toLowerCase();

                        // Check for valid UTR format
                        const isValidUTR =
                            /^k?\d{10}k?$/.test(normalized) || // 10 digits, optionally starting or ending with "k"
                            /^k?\d{13}k?$/.test(normalized); // 13 digits, optionally starting or ending with "k"

                        return (
                            isValidUTR ||
                            'Your UTR should be 10 or 13 digits, with or without spaces, and may start or end with "K".'
                        );
                    }
                ];
            case TwilioCountriesAbbr.NEW_ZEALAND:
                return [(value: string) => /^[0-9]{13}$/.test(value) || 'Your NZBN should be 13 digits long'];
            case TwilioCountriesAbbr.INDIA:
            default:
                return [(value: string) => !!value || 'This field is required'];
        }
    };

    /**
     * Get the business registration tooltip message.
     *
     * @param country
     */
    public getBusinessRegistrationTooltip(country: TwilioCountriesAbbr | string): string {
        switch (country) {
            case TwilioCountriesAbbr.UNITED_STATES:
                return 'Required to enable the Texting and/or Call Recording features. Note: Include the dash.';
            case TwilioCountriesAbbr.AUSTRALIA:
                return 'Required to enable the Texting and/or Call Recording features. Note: Include the spaces.';
            case TwilioCountriesAbbr.UNITED_KINGDOM:
                return 'Required to enable the Texting and/or Call Recording features. Note: Include spaces if your number has them.';
            case TwilioCountriesAbbr.CANADA:
            case TwilioCountriesAbbr.NEW_ZEALAND:
            case TwilioCountriesAbbr.INDIA:
            default:
                return 'Required to enable the Texting and/or Call Recording features.';
        }
    }

    /**
     * Whether to show the apply crm number button or not. It depends on the dlc registration status & stage
     *
     * @param dlcRegistrationStatus
     */
    public showApplyCrmNumberButton(dlcRegistrationStatus: DlcRegistrationStatusConstants | null): boolean {
        return !dlcRegistrationStatus || dlcRegistrationStatus === DlcRegistrationStatusConstants.DRAFT || dlcRegistrationStatus === DlcRegistrationStatusConstants.REJECTED;
    }

    /**
     * Show the status label based on the dlc registration status and dlc registration stage
     *
     * @param dlcRegistrationStatus
     * @param dlcRegistrationStage
     */
    public showDlcStatusMessage(dlcRegistrationStatus: DlcRegistrationStatusConstants | null, dlcRegistrationStage: DlcRegistrationStageConstants | null): boolean {
        if (!dlcRegistrationStatus || !dlcRegistrationStage) {
            return false;
        }
        if (dlcRegistrationStatus === DlcRegistrationStatusConstants.APPROVED && dlcRegistrationStage === DlcRegistrationStageConstants.A2P_APPROVED) {
            return true;
        }
        return dlcRegistrationStatus === DlcRegistrationStatusConstants.REJECTED || dlcRegistrationStatus === DlcRegistrationStatusConstants.DRAFT || dlcRegistrationStatus === DlcRegistrationStatusConstants.PROCESSING;
    }

    /**
     * Show the status label based on the dlc registration status and dlc registration stage
     *
     * @param dlcRegistrationStatus
     * @param dlcRegistrationStage
     * @param hasSingleCenter
     */
    public getStatusLabel(dlcRegistrationStatus: DlcRegistrationStatusConstants | null, dlcRegistrationStage: DlcRegistrationStageConstants | null, hasSingleCenter: boolean): string {
        if (!dlcRegistrationStatus || !dlcRegistrationStage) {
            return '';
        }

        const phoneNumberText = hasSingleCenter ? 'Number' : 'Numbers';
        const phoneNumber = getCrmPhoneNumberPluralizedLabel(hasSingleCenter);

        if (dlcRegistrationStatus === DlcRegistrationStatusConstants.APPROVED && dlcRegistrationStage === DlcRegistrationStageConstants.A2P_APPROVED) {
            return `Your CRM System Phone ${phoneNumberText} been approved. Click "Edit" on the Location Information table to find the "CRM System Phone" and related settings under "Location Phone".`;
        }

        if (dlcRegistrationStatus === DlcRegistrationStatusConstants.REJECTED) {
            return `Your CRM System Phone ${phoneNumberText} rejected, likely because the data provided in the "Account Information" section was inaccurate. Correct any errors and submit again, or contact Customer Support for additional assistance.`;
        }
        return `The application process to obtain ${phoneNumber} has been submitted to our third party provider. Approval can take a few days to a few weeks. This message will update once the application has been processed.`;
    }

    /**
     * Clean social media links by removing root parts like domain prefixes.
     *
     * @param dto The client info DTO to clean (modified in place)
     */
    public cleanSocialMediaLinks(dto: ClientInfoDto): void {
        if (!dto.social_media) {
            return;
        }

        // Define all possible prefixes once
        const prefixes = [
            'http://',
            'https://',
            'www.facebook.com/',
            'facebook.com/',
            'www.youtube.com/',
            'youtube.com/',
            'www.pinterest.com/',
            'pinterest.com/',
            'www.twitter.com/',
            'twitter.com/',
            'www.instagram.com/',
            'instagram.com/',
            'www.linkedin.com/',
            'linkedin.com/',
            'www.yelp.com/',
            'yelp.com/'
        ];

        // Clean each social media platform
        if (dto.social_media.facebook) {
            dto.social_media.facebook = this.removeAllPrefixes(dto.social_media.facebook, prefixes);
        }

        if (dto.social_media.twitter) {
            dto.social_media.twitter = this.removeAllPrefixes(dto.social_media.twitter, prefixes);
        }

        if (dto.social_media.youtube) {
            dto.social_media.youtube = this.removeAllPrefixes(dto.social_media.youtube, prefixes);
        }

        if (dto.social_media.linkedin) {
            dto.social_media.linkedin = this.removeAllPrefixes(dto.social_media.linkedin, prefixes);
        }

        if (dto.social_media.instagram) {
            dto.social_media.instagram = this.removeAllPrefixes(dto.social_media.instagram, prefixes);
        }

        if (dto.social_media.pinterest) {
            dto.social_media.pinterest = this.removeAllPrefixes(dto.social_media.pinterest, prefixes);
        }

        if (dto.social_media.yelp !== null) {
            dto.social_media.yelp = this.removeAllPrefixes(dto.social_media.yelp, prefixes);
        }

    }

    /**
     * Helper method to remove all prefixes from a string
     *
     * @param value The value to clean
     * @param prefixes Array of prefixes to remove
     * @returns The cleaned string
     */
    private removeAllPrefixes(value: string, prefixes: string[]): string {
        if (!value) return value;

        let result = value;
        let wasChanged = true;

        // Keep removing prefixes until no more can be removed
        // This handles cases with multiple prefixes
        while (wasChanged) {
            wasChanged = false;
            for (const prefix of prefixes) {
                if (result.startsWith(prefix)) {
                    result = result.substring(prefix.length);
                    wasChanged = true;
                    break;
                }
            }
        }

        return result;
    }

}
