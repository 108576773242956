// DlcRegistrationConstants.ts

// Organization Types
export enum DlcOrgType {
    NEW = 'NEW',
    LEGACY = 'LEGACY'
}

// Registration Statuses
export enum DlcRegistrationStatusConstants {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    PROCESSING = 'PROCESSING',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED'
}

// 10DLC Data Validation Stages and other stages
export enum DlcRegistrationStageConstants {
    // 10DLC Data Validation Stages
    DATA_INVALID = '10DLC_DATA_INVALID', // Client information is invalid or incomplete
    DATA_VALID = '10DLC_DATA_VALID', // All required client information is available and valid

    // SCP (Secondary Customer Profile) Stages
    SCP_DRAFT = 'SCP_DRAFT', // Secondary customer profile created but not submitted
    SCP_INVALID = 'SCP_INVALID', // Secondary customer profile evaluation returned non-compliant
    SCP_VALID = 'SCP_VALID', // Secondary customer profile evaluation returned compliant
    SCP_PENDING = 'SCP_PENDING', // Secondary customer profile submitted and pending Twilio review
    SCP_APPROVED = 'SCP_APPROVED', // Secondary customer profile approved by Twilio
    SCP_REJECTED = 'SCP_REJECTED', // Secondary customer profile rejected by Twilio

    // TP (Trusthub Profile) Stages
    TP_DRAFT = 'TP_DRAFT', // Trusthub profile created but not submitted
    TP_INVALID = 'TP_INVALID', // Trusthub profile evaluation returned non-compliant
    TP_VALID = 'TP_VALID', // Trusthub profile evaluation returned compliant
    TP_PENDING = 'TP_PENDING', // Trusthub profile submitted and pending Twilio review
    TP_APPROVED = 'TP_APPROVED', // Trusthub profile approved by Twilio
    TP_REJECTED = 'TP_REJECTED', // Trusthub profile rejected by Twilio

    // Brand Stages
    BRAND_PENDING = 'BRAND_PENDING', // Brand submitted and pending review
    BRAND_APPROVED = 'BRAND_APPROVED', // Brand approved by Twilio
    BRAND_REJECTED = 'BRAND_REJECTED', // Brand rejected by Twilio

    // A2P Campaign Stages
    A2P_PENDING = 'A2P_PENDING', // A2P Campaign submitted and pending review
    A2P_APPROVED = 'A2P_APPROVED', // A2P Campaign approved by Twilio
    A2P_REJECTED = 'A2P_REJECTED' // A2P Campaign rejected by Twilio
}
